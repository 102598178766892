import * as React from "react";

const Logo = (props) => (
    <svg
    // width="100%"
    height="45"
    scale={props.scale} // Pass the scale as a prop
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 651.63 291.48"
    {...props}
  >
     <defs>
      <style>{".cls-1{fill:#f8971d;stroke-width:0}"}</style>
    </defs>
    <path
      d="m111.23 187.16-12.35-3.11-9.81-59.41c-.26-1.68-.49-3.14-.67-4.39-.18-1.25-.3-2.31-.35-3.19l-.16-.04c-.26 1.47-.65 3.32-1.16 5.58s-1.15 4.89-1.92 7.93l-11.85 46.99-11.46-2.89 21.1-83.71 13.17 3.32 9.41 57.57c.2 1.29.39 2.57.58 3.84.19 1.27.35 2.48.47 3.63l.16.04c.14-1 .43-2.47.86-4.41s1.01-4.33 1.73-7.17l11.89-47.17 11.46 2.89-21.1 83.72ZM151.91 58.61h14.61v167.47h-14.61zM246.12 132.57v15.35h-68.03v-15.35z"
      className="cls-1"
    />
    <path
      d="M148.87 8.47c-74 0-133.98 59.98-133.98 133.97s59.98 133.98 133.98 133.98 133.97-59.99 133.97-133.98S222.86 8.47 148.87 8.47Zm0 253.45c-65.99 0-119.48-53.49-119.48-119.48S82.88 22.96 148.87 22.96s119.47 53.5 119.47 119.48-53.49 119.48-119.47 119.48ZM306.58 16.34h13.94v252h-13.94zM379.96 63.04h16.83v69.54h-13.46V89.3l-10.82 43.28h-4.33L357.36 89.3v43.28H343.9V63.04h16.83l9.62 34.62 9.62-34.62ZM428.15 119.4v13.18h-24.19V119.4h4.81V76.22h-4.81V63.04h24.19v13.18h-4.81v43.18h4.81ZM460.75 119.4v-8.46h14.62v12.02l-9.62 9.62H434.4V72.61l10.39-9.57h30.58V89.3h-14.62V76.27h-11.73v43.14h11.73ZM495.76 91.6h12.5V63.04h14.57v69.54h-14.57v-27.79h-12.5v27.79h-14.57V63.04h14.57V91.6ZM529.04 63.04h33.76v13.22h-19.33v13.42h18.37v12.98h-18.18v16.73h19.62v13.18h-34.24V63.04ZM583.23 132.58h-14.62V74.06l11.21-11.01h30.39v69.54h-14.57v-22.84h-12.41v22.84Zm12.41-36.07V76.26h-12.41v20.25h12.41ZM616.7 132.58V63.04h14.57v56.36h20.39v13.18H616.7ZM358.47 189.66v29.91H343.9v-59.92l9.62-9.62h23.61v13.22h-18.66v13.42h17.21v12.98h-17.21ZM383.19 150.04h33.76v13.22h-19.33v13.42h18.37v12.98h-18.18v16.74h19.62v13.18h-34.24v-69.54ZM422.76 219.58v-69.54h14.57v56.36h20.39v13.18h-34.96ZM463.97 150.04h41.98v69.54h-41.98v-69.54Zm14.62 56.36h12.79v-43.14h-12.79v43.14ZM554.57 190.63v28.95h-32.6l-9.62-9.62v-59.92h29.82l9.86 9.19v19.14l-6.83 5.48 9.38 6.78Zm-16.97-12.99v-14.38h-10.67v14.38h10.67Zm2.35 28.76v-15.77h-13.03v15.77h13.03ZM560.73 150.04h33.76v13.22h-19.33v13.42h18.37v12.98h-18.18v16.74h19.62v13.18h-34.24v-69.54ZM638.54 219.58h-38.23v-21.64h14.57v8.46h9.04v-11.06l-23.13-17.46v-27.84h37.32v20.68h-13.22v-7.41h-9.52v9.09l23.18 17.41v29.77Z"
      className="cls-1"
    />
  </svg>
)
export default Logo
